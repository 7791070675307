<template>
  <d-virtual-table
    :grid="grid"
    :items="items"
    :columns="columns"
    with-pagination
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        v-if="isAdminOrSuper"
        :class="btnClass(true)"
        icon="mdi-wallet-plus"
        color="primary"
        label="Create balance change"
        :to="{
          name: 'CreateBalanceChange',
        }"
      />
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-card :item="props" />
    </template>
  </d-virtual-table>
</template>

<script>
import { columns } from './config';
import { DGridCard } from './features';
import { ROLES } from '@/shared/constants';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';
import { authUtils } from '@/shared/utils';

export default {
  components: { DVirtualTable, DGridCard },
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [withActionTableClasses],
  data: () => ({
    columns,
  }),
  computed: {
    isAdminOrSuper() {
      const role = authUtils.getRoleByHierarchy();
      return ROLES.ROLE_SUPERVISOR === role || ROLES.ROLE_ADMIN === role;
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 54em;
}
</style>
